.DividerHorizontal
{
    width: 100%;
    height: 1px;    
}

.VerticalDivider
{
    width: 1px;
    height: 100%;    
}

.dark
{
    background-color: #303030;
}

.light
{
    background-color: white;
}