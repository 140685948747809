
.main
{
    margin: 0 2rem;    
    padding-bottom: 20px;    
    position: relative;
    .buttonContainers
    {        
        position: fixed;
        float: right;
        margin: 1rem;
        bottom: 0;
        right: 0;
        z-index: 12;

        .buttonIcons
        {
            cursor: pointer;
            margin-inline: 5px;
            padding: 9px;            
            border-radius: 6px;        
            background-color: rgb(0, 0, 0);
            transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            outline: none;
            border: none;

            font-weight: bold;
            font-family: "Inter";                
            text-shadow: 0 0 2.2em #4f4f4f;    

            &:hover
            {
                color:#6a00ff;                                        
            }
        }
    }

    .date
    {
        opacity: .5;
        color: white;
        font-family: "Inter";
        font-size: 15px;
        line-height: 120%;
        font-weight: 200;
    }

    .title
    {
        font-family: "DM Sans";
        font-weight: 100;
        font-size: 18px;
        color: white;
    }

    .ProfilePicture
    {
        width: 30px;
        display: flex;
        height: 30px;
        padding-block: 1rem;
        align-items: center;
        gap: 8px;  

        .image 
        {
            max-width: 40px;
            max-height: 40px;
            border-radius: 50%;                        
        }

        .username
        {
            font-family: "Fira Sans";
            font-weight: initial;
            font-size: 17px; 
            transition: color 0.2s ease;

            &:hover
            {
                color:#0095ff;
            }
        }
    }

    .details
    {
        display: flex;
        flex-flow: wrap;
        gap: 5px;    
        .author
        {
            font-size: 13px;                              
            padding: 5px 4px;            
            border-radius: 6px;
            color: #6a00ff;
            text-shadow: 0 0 2.2em #6a00ff;

            background-color: rgb(11 0 32); 
        }
    }
}