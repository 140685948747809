@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

body
{
    font-family: 'Inter';
    color: white;
    background-color: #202020;    
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    min-height: 100vh;
}

body::-webkit-scrollbar
{
    background-color: #000000;
    width: 4px;
}

body::-webkit-scrollbar-thumb
{
    background-color: #0076f1;
    border-radius: 100px;
}

.Divider
{
    height: 1rem;
    width: 1px;
    background-color: #3d3d3d;
}

#root
{
    height: 100vh;
    margin: 0;
    padding: 0;
}

* {
    text-decoration: none;
    color:white;
}