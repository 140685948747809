.is-active 
{
    background: #202020;
    color: #fff;
}

select {
    font-size: inherit;
    font-family: inherit;
    color: white;
    margin: 0.1rem;
    border: 1px solid black;
    border-radius: 0.3rem;
    padding: 0.1rem 0.4rem;
    background: #202020;
    accent-color: black;
}

.optionbar {

    align-items: center;
    border-bottom: 3px solid #0d0d0d;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    padding: 0.25rem;


    .divider {
        background-color: rgb(255 255 255 / 29%);
        height: 1.25rem;
        margin-left: 0.5rem;
        margin-right: 0.75rem;
        width: 2px;
    }

    .menuitem {
        background-color: transparent;
        border: none;
        border-radius: 0.4rem;
        height: 1.75rem;
        margin-right: 0.25rem;
        padding: 0.25rem;
        width: 1.75rem;
        cursor: pointer;
        color: white;

        &:hover {
            background-color: #3775eb;
            color: #fff;
        }

        i {
            display: flex;
            justify-content: center;
        }
    }
}

.MenuEditor {
    background-color: rgb(24, 24, 24);
    border: 3px solid #000000;
    border-radius: 0.75rem;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    max-height: 26rem;


    .Content {
        flex: 1 1 auto;
        overflow-x: hidden;
        overflow-y: auto;
        padding-inline: 1.25rem 1rem;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            background-color: transparent;
            width: 4px;
            height: 20px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #0076f1;
            border-radius: 100px;
        }
    }
}

.PostTitle {
    background-color: transparent;    
    outline: none;
    border: none;
    color: white;
    padding: .5rem;
    font-family: "Inter";
    font-weight: 500;
    font-size: large;
    border-bottom: 3px solid #0d0d0d;
}

.PostActions {
    margin-top: 1rem;
    float: right;
}


.submit {
    background-color: #0076f1;
    border: none;
    outline: none;
    padding: 0.4rem 1rem;
    cursor: pointer;

    justify-content: center;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    color: white;
    border-radius: 4px;
}

.Modal {
    margin: 0;
    left: 0;
    top: 0;
    position: absolute;
    background-color: #000000;
    width: 100vw;
    height: 100vh;
    padding: 0;    
    z-index: 2;
    backdrop-filter: blur(4px);

    .Form {
        background-color: transparent;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        

        .FormBox {
            padding: 1rem;
            background-color: #131313;
            width: 400px;
            height: 200px;
            border-radius: 8px;
            z-index: 3;
            

            textarea {
                resize: none;
                border: none;
                outline: none;
                color: white;
                padding: 0.5rem;
                background-color: #131313;
            }
        }
    }
}

.ButtonOverlay
{
    background-color: transparent;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 11;
    width: 100vw;
    height: 100vh;
}

.Notifier
{
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 1rem;

    .NotifierData
    {
        background-color: #0076f1;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        padding: .5rem;
    }

    .NotifierDataError
    {
        .ErrorInfo
        {
            font-size: 12px;
            font-weight: 500;
            font-family: "Inter";
        }
    }
}

.Notifier
{
    
}

.NotifierHide
{
    transition: all 4s ease-in-out;
    margin-left: -100px;
}

.NotifierShow
{
    margin-left: 0;
}


.input-colorpicker {
    border: none;
    outline: none;
    inline-size: 0px;
    background-color: transparent;
}

::-webkit-color-swatch-wrapper {
    width: 25px;
    height: 25px;
    padding: 0;
    margin: 0;
}

::-webkit-color-swatch {
    border: none;
    outline: none;
    border-radius: 4px;
}

::-moz-color-swatch
{
    border: none;
    outline: none;
    border-radius: 4px;
    width: 25px;
    height: 25px;    
}