.BlueStyled
{
    margin-top: 1rem;      
    padding: 7px;
    width: 250px;
              
    font-weight: 600;
    background-color: #006AB7;
    border-color:transparent;
    transition: background-color .2s cubic-bezier(0.39, 0.575, 0.565, 1);

    border-radius: 4px;   
    color:white;
    margin-bottom: 2rem;    

    cursor: pointer;

    &:hover
    {
        background-color: #00375F;  
    }
}    


.PurpleStyled
{
    margin-top: 1rem;      
    padding: 7px;
    width: 250px;
              
    font-weight: 600;
    background-color: #6b00b7;
    border-color:transparent;
    transition: background-color .2s cubic-bezier(0.39, 0.575, 0.565, 1);

    border-radius: 4px;   
    color:white;
    margin-bottom: 2rem;    

    cursor: pointer;

    &:hover
    {
        background-color: #33005f;  
    }
}    

.Send
{
    margin-top: 1rem;      
    padding: 7px;
              
    font-weight: 600;
    background-color: #006AB7;
    border-color:transparent;
    transition: background-color .2s cubic-bezier(0.39, 0.575, 0.565, 1);

    border-radius: 4px;   
    color:white;
    margin-bottom: 2rem;    

    cursor: pointer;

    &:hover
    {
        background-color: #00375F;  
    }
}   