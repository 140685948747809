.profile-main-container
{
    color: black;
    
    display: flex;
    justify-content: center;
    
    .profile-container-box
    {            
        width: 650px;
        margin: 1rem;
        padding-bottom: 0;
        height: calc(100% - 1rem);

        .profile-topbar
        {
            min-height: 10px;
            background-color: rgb(0, 119, 255);
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
        }

        .profile-bottombar
        {
            color:white;
            height: 100%;
         
            margin: 0;
            padding: 1rem;
            background-color: rgb(0, 0, 0);
            
            .profile-title
            {
                margin-bottom: 1rem;
            }

            h3
            {
                font-family: "Fira Sans";
                font-weight: 300;

                margin: 0;
            }

            .profile-image-content
            {
                position: relative;
                margin-left: 1rem;
                float: right;                
                
                img
                {               
                    transition: filter .2s ease-in-out;
                    border-radius: 6px;     
                    max-height: 70px;
                    max-width: 70px;                        
                }

                .brig
                {
                    filter: brightness(0.3);
                }

                .profile-image-options
                {
                    position: absolute;
                    width: 100%;
                    bottom: 0;
                    margin-block: 1rem;
                    cursor: pointer;
                    text-align: center;         
                    
                    button
                    {
                        background-color: transparent;
                        cursor: pointer;
                        border: none;
                        outline: none;
                    }
                }
            }

            .profile-content
            {
                word-wrap: break-word;

                h4
                {
                    margin-bottom: 0;
                    font-family: "Fira Sans";
                    font-weight: 300;
                }

                h1,h2,h3,h4,h5,h6,p,a
                {
                    margin: 0;
                }

                .profile-content-item
                {
                    font-size: small;
                    margin-bottom: 1rem;
                }

                .profile-view-posts
                {
                    
                    color:rgb(0, 119, 255);
                }

                .profile-joined-date
                {
                    margin-top: 1rem;
                    font-size: 15px;
                    font-weight: 300;
                }
            }
        }
    }
}

.error-title{
    margin: 1rem;
    color: #ff2e2e;
    font-family: "Inter";
    font-weight: 300;
    font-size: medium;
}

.Notifier
{
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 1rem;

    .NotifierData
    {
        background-color: #0076f1;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        padding: .5rem;
    }

    .NotifierDataError
    {
        .ErrorInfo
        {
            font-size: 12px;
            font-weight: 500;
            font-family: "Inter";
        }
    }
}

.Notifier
{
    
}

.NotifierHide
{
    transition: all 4s ease-in-out;
    margin-left: -100px;
}

.NotifierShow
{
    margin-left: 0;
}