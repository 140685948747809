.inputbox
{
    margin: 1rem 0;    
    
    display: block;    
    background-color: #343434;    
    width: 280px;
    padding: 7px;
    
    border-radius: 4px;        
    font-weight: 600;
    outline: none;

    border-color:transparent;
    color: white;

    font-family: 'Inter';
}    

.text
{
    color: rgb(255 255 255);
    font-family: "Inter";
    font-weight: 400;
    font-size: 13px;
}