.Container
{    
    
}

.content 
{
    color: rgb(255, 255, 255);
    min-width: 300px;
    background-color: rgb(19,19,19);
    padding: 1.8rem;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Error
{
    transform: translate(0px, -10px);
    font-family: "Fira Sans";
    font-size: medium;
    font-weight: 500;
}

.cta
{
    margin-top: 1rem;
    background-color: rgb(27, 27, 27);
    border-radius: 5px;
    padding: .4rem 1rem;    
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-family: "Inter";
    font-weight: 500;
}

.Description
{
    font-family: "Fira Sans";
    font-size: medium;
    font-weight: 300;
}