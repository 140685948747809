.container
{
    .title
    {        
        margin: 0;
        background-color: black;
        font-weight: 200;
        color: white;
        text-align: center;
        padding: 1rem;
    }

    .list
    {               
        margin: 2rem;
        margin-bottom: 1rem;
        padding: 1rem;
        background-color: rgb(61, 61, 61);
        border-radius: 8px;  

        .frame
        {          
            padding: 8px;           
            height: 380px;
            overflow: auto;
            gap: 1rem;
            
            &::-webkit-scrollbar
            {
                background-color: transparent;
                width: 5px;
                border-radius: 4px;
            }

            &::-webkit-scrollbar-thumb
            {
                background-color: blueviolet;                                
                border-radius: 2px;
            }

            &::-webkit-scrollbar-track
            {
                border-radius: 4px;
                background-color: rgb(21, 21, 21);
            }

            .listitems
            {
                display: grid;
                gap: 1rem;
                .item
                {
                    display: flex;
                    line-height: 20px;
                    flex-direction: column;                    
                    border-radius: 5px;
                    padding: 0.7rem;
                    background-color: rgb(20,20,20);
                    cursor: pointer;
                    align-items: flex-start;

                    .token
                    {
                        padding: 0;
                        color: white;
                        font-weight: 500;
                        font-size: 14px;
                    }

                    .usedBy
                    {
                        padding: 0;
                        color: white;
                        font-weight: 300;
                        font-size: 12px;
                    }

                    &::before
                    {
                        position: absolute;
                        padding: 1rem;
                        background-color: beige;
                    }
                }
            }
        }
    }
}