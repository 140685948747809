.navigator
{
    width: 100vw;    
    padding-bottom: 1rem;
    
    .frame
    {
        margin: auto;
    
        display: flex;
        justify-content: center;
        gap: 8px; 
        
    
        padding: 7px;
        border-radius: 5px;

        width: 100px;

        .pagingbutton
        {
            cursor: pointer;

            display: flex;
            justify-content: center;
            align-items: center;

            background-color: #3a00b7;
            width: 30px;
            height: 30px;
            
            border-radius: 2px;

            .page-number
            {
                color: white;
                font-weight: 500;
                font-size: 15px;
            }            
        }

        .greyed-out
        {
            background-color: gray;
            pointer-events: none;    
        }
    }
}