@import "../../sass/mixins.scss";

.container
{        
    margin: 3rem 0px;
    transition: transform 0.3s ease-out;
    display: flex;
    justify-content: center;    
}

.form
{
    text-align: center;
    
    display: flex;
    justify-content: center;    

    background-color: #151515;
    padding-inline: 4rem;
    padding-block: 2rem;
    border-radius: 4px;
}

.form_inputs
{
    display: grid;
    justify-items: center;
    text-align: start;
}

.hidden
{    
    display: none;    
}

.shown
{    
    display: block;    
}

.text_error
{
    font-size:13px;
    font-family: "Fira Sans";
    font-weight:300;
    color:red;
    
    text-align: start;
    width: 100%;
    word-break: break-all;
}