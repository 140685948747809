.content
{

}

.editoroptions {
    margin: 2rem;
}

.postoptions{
    margin: 2rem;
}