@import "../../sass/mixins.scss";

.mainnav
{
    
}

.navbar
{
    display: flex;    
    align-items: center;    

    width: 100%;
    height: 5rem;    
    background-color: black;  
    
    span
    {
        color:white;
    }

    svg
    {
        margin-inline: 1rem;  
    }        

    @include screen_check(moble)
    {
        justify-content: center;
    }
}

.NavbarItemGroup
{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-inline: 2rem;
}

.NavButton
{
    padding: 4px;
    
    transition: background-color .2s cubic-bezier(0.39, 0.575, 0.565, 1);

    border-radius: 4px;   
    background-color: #006AB7;
    border-color:transparent;

    color:white;
    font-weight: 600;

    cursor: pointer;

    &:hover
    {
        background-color: #00375F;  
    }
}

.username
{
    margin-right: 0.5rem;
}

.userdisplaycontent
{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    cursor: pointer;

    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
                    
    &:hover
    {           
        transform: translate(0px, 2px);   
        transform: scale(1.04);                     
    }
}

.layoutpanel
{
    right: 0;
    position: absolute;
    z-index: 5;
    
    .form
    {
        background-color: black;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        width: 150px;        

        .list
        {
            padding: 1rem;            
            gap: 1rem;
            display: grid;

            .item
            {
                cursor: pointer;
                padding: .3rem;
                                
                display: flex;
                gap: 6px;
                font-weight: 200;
                font-size: 15px;

                &:hover
                {
                    color: white;
                }
            }
        }
    }
}

.iconsize2
{
    color: white;
    font-size: 1.5rem;
}

.iconsize3
{
    color: white;
    font-size: 1.2rem;
}
