$tablet-width: 768px;
$moble-width: 453px;
$desktop-width: 1024px;

@mixin screen_check($breakpoint) 
{
    @if($breakpoint == pc)  
    {
        @media (max-width: $desktop-width) { @content }
    };

    @if($breakpoint == moble)  
    {
        @media (max-width: $moble-width) { @content }
    };
}