.Main
{
    
}

.Title
{
    margin-inline: 1rem;
    font-family: "Fira Sans";
    font-weight: 300;
}

.Posts
{
    flex-flow: row wrap;
    padding: 2rem;
    gap: 1rem;
    display: flex;
    justify-content: center;
}

.createPost
{
    position: fixed;
    bottom: 0;
    right:0;

    .Form
    {
        margin: 2rem;
        padding: 1rem;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: #006AB7;
        transition: all .3s ease-in-out;

        &:hover
        {            
            cursor: pointer;
            transform: scale(1.1);
        }

        i
        {         
            color:white;   
            font-size: 1.7rem;
        }
    }
}