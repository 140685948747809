/* TextItem Roles */

.Godly
{    
    font-size: 16px;
    font-family: "Fira Sans";
    font-weight: 500;

    text-shadow: 0 0 5px #272727;
    background: linear-gradient(90deg,#00b3ff 0,#ab00b4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;    
}

.Default
{    
    font-size: 16px;
    font-family: "Fira Sans";
    font-weight: 500;
    color:white;
}