@import "../../sass/mixins.scss";
@import "../../sass/style.scss";

.PostContainer
{    
    background-color: black;
    position: relative;
    width: 350px;    
    box-shadow: #0062ff18 0px 0px 5px 5px;    
}

.PostFrame
{        
    display: flex;
    align-items: center;
    padding: 1rem;    
    img
    {
        height: 40px;
    }
}

.PostTitle
{    
    font-size: 14px;
    font-family:"Fira Sans";
    font-weight:400;
    text-align: center;
    margin-inline: 1rem;
}

.PostDescription
{
    padding-inline: 1rem;
    font-size: 12px;
    font-family:"Fira Sans";
    font-weight:300;    
    text-align: center;
}

.ProfilePicture
{     
    border-radius: 50%;
}

.ProfilePictureCircle
{
    display: flex;
    justify-content: center;
    align-items: center;
        
    background-color: #006AB7;
    border-radius: 50%;
    min-height: 45px;
    min-width: 45px;

    margin-right: 1rem;   
}

.Divider
{
    width: 100%;
    height: 1px;
    background-color: #303030;
}

.PostInfo
{
    padding: .5rem 0;        
    word-wrap: break-word;
}

.ContainerInfo
{
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;

    .ContainerBox
    {
        width: 35px;
        height: 35px;

        .PostReadMoreButton
        {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

            width: 100%;
            height: 100%;
            
            font-size: large;

            background-color: #00000000;
            color: white;
            transition: transform .6s cubic-bezier(0.075, 0.82, 0.165, 1);
            &:hover
            {
                transform: scale(1.3);
            }
        }
    }
}